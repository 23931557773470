import React from "react"
import "../styles/all.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroBar from "../components/hero-bar"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const CampsPage = () => {
  const data = useStaticQuery(graphql`
    query CampsPageQuery {
      handBasketball: file(relativePath: { eq: "hand-basketball.png" }) {
        childImageSharp {
          fixed(height: 80) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Camps" />
      <HeroBar subtitle="Camp Information" />
      <div className="has-background-white-ter has-margin-top-15">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <div className="content">
              <div className="has-margin-top-15 has-margin-15-mobile">
                <div className="tile">
                  <h1 className="title has-padding-bottom-5 why-cav">
                    Camps and clinics
                  </h1>
                </div>
                <div className="tile">
                  <h3 className="subtitle">
                    Upcoming opportunities to take your game to the next level!
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <div className="content">
            <div className="column is-12 section-header">
              <h4 className="title has-margin-bottom-5">Clinics</h4>
            </div>
            <div className="column is-12 has-margin-bottom-25">
              <strong>Local opportunity that starts March 23!</strong>
              <div className="buttons are-medium has-margin-top-10">
                <a
                  className="button is-primary is-outlined"
                  href="/docs/precision-shooting.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Precision Shooting Program
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="columns is-mobile">
        <div className="column is-12 section-icon">
          <Img
            fixed={data.handBasketball.childImageSharp.fixed}
            alt="Cavalry Basketball"
          />
        </div>
      </div>
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <div className="content">
            <div className="column is-12 section-header">
              <h4 className="title has-margin-bottom-5">Camps</h4>
            </div>
            <div className="column is-12 has-margin-bottom-25">
              <strong>Summer Camp Opportunity!</strong>
              <p className="is-size-4 has-margin-top-10">
                Coach Jason Simons will be attending a PGC Camp (Point Guard
                College) this summer. This camp is a phenomenal camp, not just
                for point guards, but for all players that want to actually lead
                their teams and want to become playmakers at their position.
              </p>
              <p className="is-size-4">
                This camp is highly recommended for those players who want to
                take their mind and their skills to whole another level. In
                fact, Coach has taken his own sons and attended this camp three
                summers in a row, this has allowed Coach to have a great
                relationship with PGC and Director Chad Songy.
              </p>
              <p className="is-size-4">
                Ultimately, Jason would like to take up to 12 players (7th grade
                or older) with him this summer. If there is enough interest
                Jason would then be able to ask the director for a team
                discount.
              </p>
              <div className="is-size-4">
                This camp includes:
                <ul>
                  <li>6-7 hours of productive court time daily</li>
                  <li>daily video sessions</li>
                  <li>daily classroom sessions</li>
                  <li>all meals</li>
                  <li>room/board</li>
                </ul>
              </div>
              <p className="is-size-4">
                It is well worth the $795 fee. For more detailed facts about the
                camp use the link below. Please let Jason know if you are
                interested in having your child attend this camp. Again, if he
                can get 5-12 players to attend he may be able to get a team
                discount.
              </p>
              <p className="is-size-4">
                Coach Simons does not benefit financially by players joining him
                at the camp; however he has had the great benefit as a Father
                and a Coach of the camp making his sons and players better
                people and better basketball players.
              </p>
              <p className="is-size-4">
                Possible sessions Jason can attend: June 23rd-June 27th in
                Denton, Texas or June 29th-July 3rd in Boulder, Colorado. We
                look forward to hearing from you soon. These camps tend to fill
                up quickly. So the sooner we sign up the better. Email or call
                Jason 402-616-0766 with questions.{" "}
              </p>
              <p className="is-size-4">
                <a
                  href="https://pgcbasketball.com/camps"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://pgcbasketball.com/camps
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CampsPage
